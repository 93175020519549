<template>
  <b-card>
    <div class="row">
      <div class="col-sm-10"><h1>Company: <b>{{user_login.company.name}}</b></h1></div>
    </div>
    <div class="row">
      <div class="col-sm-3">
        <div class="text-center" v-if="activetab==1">
          <img v-if="company&&company.logo" :src="computed_logo(company.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage_company" id="fileControl_company">
        </div>
        <div class="text-center" v-if="activetab==2">
          <img v-if="branch&&branch.logo" :src="computed_logo(branch.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage_branch" id="fileControl_branch">
        </div>
        <br>
        <ul class="list-group" v-if="business=='cafe'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>
        <ul class="list-group" v-if="business=='vlxd'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_car')}}</strong></span> <b-badge variant="success">{{info.car}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_project')}}</strong></span> <b-badge variant="success">{{info.project}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='dentist'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_doctor')}}</strong></span> <b-badge variant="success">{{info.doctor}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='pharmacy'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_doctor')}}</strong></span> <b-badge variant="success">{{info.doctor}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='karaoke'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>

        <ul class="list-group" v-else-if="business=='food'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='pawn'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='football'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='tole'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='hotel'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='hostel'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='water'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='bida'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='spa'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_object',{"obj":$t('common.table')})}}</strong></span> <b-badge variant="success">{{info.object}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_doctor')}}</strong></span> <b-badge variant="success">{{info.doctor}}</b-badge></li>
        </ul>
        <ul class="list-group" v-else-if="business=='oto'">
          <li class="list-group-item text-muted"><i class="fa fa-dashboard h4 fa-1x"> {{$t('common.system_info')}}</i></li>
          <li class="list-group-item text-right" v-if="!is_branch && cocon"><span class="pull-left"><strong>{{$t('common.count_branch')}}</strong></span> <b-badge variant="success">{{info.branch}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_user')}}</strong></span> <b-badge variant="success">{{info.user}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_client')}}</strong></span> <b-badge variant="success">{{info.client}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_product')}}</strong></span> <b-badge variant="success">{{info.product}}</b-badge></li>
          <li class="list-group-item text-right"><span class="pull-left"><strong>{{$t('common.count_order')}}</strong></span> <b-badge variant="success">{{info.order}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_supply')}}</strong></span> <b-badge variant="success">{{info.supply}}</b-badge></li>
          <li class="list-group-item text-right" ><span class="pull-left"><strong>{{$t('common.count_car')}}</strong></span> <b-badge variant="success">{{info.car}}</b-badge></li>
        </ul>
      </div><!--/col-3-->
      <div class="col-sm-9">
        <b-tabs class="mt-5" @changed.prevent="tabChanged">
          <b-tab v-if="is_admin || is_super" v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">
            <template v-slot:title>
              <i class="fa fa-info" aria-hidden="true"></i> {{ $t('common.company_info') }}
            </template>
            <b-card-text>
              <form id="form-company">
                <div class="row">
                  <div class="col form-group">
                    <label for="txt_tencompany">{{$t('common.company')}}</label>
                    <div v-if="$v.company.name.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.company.name.required">{{$t('common.company_required')}}</div>
                    </div>
                    <b-form-input type="text" class="form-control" v-model="company.name" maxLength="50" size="sm"
                    id="txt_tencompany" placeholder="Name" title="Nhập tên công ty."></b-form-input>
                  </div>
                  <div class="col form-group" v-show="user_login.company.business!='dentist'">
                    <label for="ddl_bill_size">{{$t('common.default_bill_size')}}</label>
                    <b-form-select id="ddl_bill_size" v-model="company.default_bill"
                      v-on:input="$v.company.default_bill.$touch" size="sm">
                        <option value="null" disabled>{{$t('common.select_default_bill_size')}}</option>
                        <option v-for="d in bill_size" :value="d.id"
                            v-bind:key="d.id">{{ $t("common.bill_size",{obj:d.name})}}</option>
                    </b-form-select>
                  </div>
                  <div class="col form-group">
                    <label for="txt_bill_name">{{$t('common.bill_text')}}</label>
                    <b-form-input type="text" class="form-control" v-model="company.bill_name" maxLength="20" size="sm"
                    id="txt_bill_name" :placeholder="$t('common.placeholder_bill_text')"></b-form-input>
                  </div>
                  <div class="col form-group">
                    <b-form-group>
                      <label for="chkcocon">{{$t('common.has_branch')}}</label>
                      <b-form-checkbox switch size="lg" id="chkcocon"
                      v-model="company.cocon"></b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                      <label for="txt_phonecompany">{{$t('common.phone')}}</label>
                      <div v-if="$v.company.phone.$error" class="pull-right">
                          <div class="text-danger" v-if="!$v.company.phone.required">{{$t('common.phone_required')}}</div>
                          <div class="text-danger" v-else-if="!$v.company.phone.numeric">{{$t('common.phone_number_only')}}</div>
                          <div class="text-danger" v-else-if="!$v.company.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                          <div class="text-danger" v-else-if="!$v.company.phone.isUnique">{{$t('common.phone_existing')}}</div>
                      </div>
                      <b-form-input type="text" class="form-control" v-model="company.phone" id="txt_phonecompany"
                      v-on:input="$v.company.phone.$touch" size="sm"
                      maxLength="20" :placeholder="this.$t('common.placeholder_phone')"></b-form-input>
                  </div>

                  <div class="col form-group">
                    <label for="txtCode">{{$t('common.code')}}</label>
                    <div v-if="$v.company.code.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.company.code.required">{{$t('common.code_required')}}</div>
                    </div>
                    <b-form-input type="text" class="form-control" v-model="company.code" id="txtCode"
                      maxLength="20" :disabled="true" size="sm"
                      placeholder="Nhập mã công ty"></b-form-input>
                  </div>
                </div>
                <div class="row" v-if="business=='dentist'">
                  <div class="col form-group">
                    <label for="txt_giay_phep_hoat_dong">Giấy phép hoạt động</label>
                    <div v-if="$v.company.giay_phep_hoat_dong.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.company.giay_phep_hoat_dong.required">Nhập giấy phép hoạt động.</div>
                    </div>
                    <b-form-input type="text" class="form-control" v-model="company.giay_phep_hoat_dong" id="txt_giay_phep_hoat_dong"
                      maxLength="50" size="sm" placeholder="Nhập giấy phép công ty"></b-form-input>
                  </div>

                  <div class="col form-group">
                    <label for="txt_ma_so_ho_so">Mã số hồ sơ</label>
                    <div v-if="$v.company.ma_so_ho_so.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.company.ma_so_ho_so.required">Nhập mã số hồ sơ.</div>
                    </div>
                    <b-form-input type="text" class="form-control" v-model="company.ma_so_ho_so" id="txt_ma_so_ho_so"
                      maxLength="50" size="sm" placeholder="Nhập mã số hồ sơ"></b-form-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <fieldset class="scheduler-border col">
                      <legend class="scheduler-border">{{$t('common.working_time')}}</legend>
                      <div class="row">
                        <div class="col-md-3">
                          <label for="txt_sang_tu">Sáng từ</label>
                          <div v-if="$v.company.sang_tu.$error" class="pull-right">
                            <div class="text-danger" v-if="!$v.company.sang_tu.required">{{$t('common.time_required')}}</div>
                          </div>
                          <b-form-input type="time" class="form-control" v-model="company.sang_tu" id="txt_sang_tu" size="sm"
                            maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                        </div>
                        <div class="col-md-3">
                          <label for="txt_sang_den">Sáng đến</label>
                          <div v-if="$v.company.sang_den.$error" class="pull-right">
                              <div class="text-danger" v-if="!$v.company.sang_den.required">{{$t('common.time_required')}}</div>
                          </div>
                          <b-form-input type="time" class="form-control" v-model="company.sang_den" id="txt_sang_den" size="sm"
                            maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                        </div>
                        <div class="col-md-3">
                          <label for="txt_chieu_tu">Chiều từ</label>
                          <div v-if="$v.company.chieu_tu.$error" class="pull-right">
                            <div class="text-danger" v-if="!$v.company.chieu_tu.required">{{$t('common.time_required')}}</div>
                          </div>
                          <b-form-input type="time" class="form-control" v-model="company.chieu_tu" id="txt_chieu_tu" size="sm"
                            maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                        </div>
                        <div class="col-md-3">
                          <label for="txt_chieu_den">Chiều đến</label>
                          <div v-if="$v.company.chieu_den.$error" class="pull-right">
                              <div class="text-danger" v-if="!$v.company.chieu_den.required">{{$t('common.time_required')}}</div>
                          </div>
                          <b-form-input type="time" class="form-control" v-model="company.chieu_den" id="txt_chieu_den" size="sm"
                            maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="row" v-if="business=='dentist'">
                  <div class="col form-group">
                    <label for="txt_des_company">{{$t('common.description')}}</label>
                    <textarea class="form-control" id="txt_des_company"
                    v-model="company.des" maxLength="500" rows="7"
                    :placeholder="$t('common.placeholder_description')"></textarea>
                  </div>
                </div>
                <div class="row" v-show="business=='hotel'||business=='hostel'||business=='cafe'||business=='bida'||business=='karaoke'||business=='spa'||business=='food'||business=='vlxd'">
                  <div class="col">
                    <fieldset class="scheduler-border col-md-12">
                      <legend class="scheduler-border h4">{{$t('common.discount_info')}}</legend>
                      <div class="row">
                        <div class="col-2 form-group">
                          <label for="txt_discount">{{$t('common.discount')}}</label>
                          <money v-model.trim="company.discount" v-bind="money_discount" class="form-control"
                            id="txt_discount" maxLength="4"></money>
                        </div>

                        <div class="col-7 form-group">
                          <label for="txt_discount_reason">{{$t('common.discount_reason')}}</label>
                          <b-form-input type="text" class="form-control" v-model="company.discount_reason" id="txt_discount_reason"
                            maxLength="200" size="sm" placeholder="Nhập lý do giảm giá (nếu có)"></b-form-input>
                        </div>
                        <div class="col-3 form-group">
                          <label for="chk_discount_apply_all">{{$t('common.discount_apply_all')}}</label>
                          <b-form-checkbox switch size="lg" id="chk_discount_apply_all"
                          v-model="company.discount_all"></b-form-checkbox>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="txt_address_company">{{$t('common.address')}}</label>
                    <textarea class="form-control" id="txt_address_company"
                    v-model="company.address" maxLength="300"
                    :placeholder="this.$t('common.placeholder_address')"></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-xs-12">
                    <button type="button" class="btn btn btn-success btn-sm" @click="saveCompany()">
                      <i class="fa fa-check"></i> {{$t('common.button.save')}}</button>
                  </div>
                </div>
              </form>
            </b-card-text>
          </b-tab>
          <b-tab v-if="is_truong_chi_nhanh" v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">
            <template v-slot:title>
              <i class="fa fa-info" aria-hidden="true"></i> {{ $t('common.branch_info') }}
            </template>
            <b-card-text>
              <form id="form-branch">
                <div class="row">
                  <div class="col-md-8 form-group">
                    <label for="txt_branch_name">{{$t('common.branch')}}</label>
                    <div v-if="$v.branch.name.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.branch.name.required">{{$t('common.branch_required')}}</div>
                    </div>
                    <b-form-input type="text" class="form-control" v-model="branch.name" maxLength="50" size="sm"
                    id="txt_branch_name" :placeholder="$t('common.placeholder_branch')"></b-form-input>
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="txt_branch_bill_name">{{$t('common.bill_text')}}</label>
                    <b-form-input type="text" class="form-control" v-model="branch.bill_name" maxLength="20" size="sm"
                    id="txt_branch_bill_name" :placeholder="$t('common.placeholder_bill')"></b-form-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                      <label for="txt_phonebranch">{{$t('common.phone')}}</label>
                      <div v-if="$v.branch.phone.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.branch.phone.required">{{$t('common.phone_reuiqred')}}</div>
                        <div class="text-danger" v-else-if="!$v.branch.phone.numeric">{{$t('common.phone_number_only')}}</div>
                        <div class="text-danger" v-else-if="!$v.branch.phone.minLength">{{$t('common.phone_ten_number')}}</div>
                        <div class="text-danger" v-else-if="!$v.branch.phone.isUnique">{{$t('common.phone_existing')}}</div>
                      </div>
                      <b-form-input type="text" class="form-control" v-model="branch.phone" id="txt_phonebranch"
                      v-on:input="$v.branch.phone.$touch" size="sm"
                      maxLength="20" :placeholder="this.$t('common.placeholder_phone')"></b-form-input>
                  </div>

                  <div class="col form-group">
                    <label for="txt_branch_code">{{$t('common.code')}}</label>
                    <b-form-input type="text" class="form-control" v-model="branch.code" id="txt_branch_code"
                      maxLength="20" :disabled="branch.id!=null"></b-form-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-if="business!='cafe' && business!='karaoke' && business!='hotel' && is_super">
                    <fieldset class="scheduler-border col">
                      <legend class="scheduler-border">{{$t('common.working_time')}}</legend>
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-2"><label for="txt_sang">{{$t('common.morning')}}</label></div>
                            <div class="col-5">
                              <label for="txt_branch_sang_tu">{{$t('common.from')}}</label>
                              <div v-if="$v.branch.sang_tu.$error" class="pull-right">
                                <div class="text-danger" v-if="!$v.branch.sang_tu.required">{{$t('common.time_required')}}</div>
                              </div>
                              <b-form-input type="time" class="form-control" v-model="branch.sang_tu" id="txt_branch_sang_tu" size="sm"
                                maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                            </div>
                            <div class="col-5">
                              <label for="txt_branch_sang_den">{{$t('common.to')}}</label>
                              <div v-if="$v.branch.sang_den.$error" class="pull-right">
                                  <div class="text-danger" v-if="!$v.branch.sang_den.required">{{$t('common.time_required')}}</div>
                              </div>
                              <b-form-input type="time" class="form-control" v-model="branch.sang_den" id="txt_branch_sang_den" size="sm"
                                maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col-2 "><label for="txt_chieu">{{$t('common.afternoon')}}</label></div>
                            <div class="col-5">
                              <label for="txt_branch_chieu_tu">{{$t('common.from')}}</label>
                              <div v-if="$v.branch.chieu_tu.$error" class="pull-right">
                                <div class="text-danger" v-if="!$v.branch.chieu_tu.required">{{$t('common.time_required')}}</div>
                              </div>
                              <b-form-input type="time" class="form-control" v-model="branch.chieu_tu" id="txt_branch_chieu_tu" size="sm"
                                maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                            </div>
                            <div class="col-5">
                              <label for="txt_branch_chieu_den">{{$t('common.to')}}</label>
                              <div v-if="$v.branch.chieu_den.$error" class="pull-right">
                                  <div class="text-danger" v-if="!$v.branch.chieu_den.required">{{$t('common.time_required')}}</div>
                              </div>
                              <b-form-input type="time" class="form-control" v-model="branch.chieu_den" id="txt_branch_chieu_den" size="sm"
                                maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label for="txt_branch_address">{{$t('common.address')}}</label>
                    <textarea class="form-control" id="txt_branch_address"
                    v-model="branch.address" maxLength="300"
                    :placeholder="this.$t('common.placeholder_address')"></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <div class="col-xs-12">
                    <button type="button" class="btn btn btn-success btn-sm" @click="saveBranch()">
                      <i class="fa fa-check"></i> {{$t('common.button.save')}}</button>
                  </div>
                </div>
              </form>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <b-modal id="viewModal" :title="$t('common.message')" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
  </b-card>
</template>

<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
      font-size: 1.2em !important;
      font-weight: bold !important;
      text-align: left !important;
      width:auto;
      padding:0 10px;
      border-bottom:none;
  }
</style>
<script>
import { required,requiredIf, minLength,maxLength,numeric,sameAs }
from 'vuelidate/lib/validators'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import datePicker from 'vue-bootstrap-datetimepicker'
import Loading from 'vue-loading-overlay'
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { Role } from '../../helpers/role.js'
import { Business } from '../../helpers/business.js'

export default {
  name: 'Profile',
  components: {Loading,datePicker,Multiselect},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      option_role:[
        { id:'super_admin',code:'super_admin',name:{"vi":"Super Admin","en":"Super Admin"}},
        { id:'admin',code:'admin',name:{"vi":"Admin","en":"Admin"}},
        { id:'sub_admin',code:'sub_admin',name:{"vi":"Sub Admin","en":"Sub Admin"}},
        { id:'truong_chi_nhanh',code:'truong_chi_nhanh',name:{"vi":"Trưởng Chi Nhánh","en":"Branch manager"}},
        { id:'user',code:'user',name:{"vi":"User","en":"User"}}
      ],
      option_role_vlxd_tole_water_oto:[
        { id:'super_admin',code:'super_admin',name:{"vi":"Super Admin","en":"Super Admin"}},
        { id:'admin',code:'admin',name:{"vi":'Admin',"en":"Admin"}},
        { id:'truong_chi_nhanh',code:'truong_chi_nhanh',name:{"vi":"Trưởng Chi Nhánh","en":"Branch manager"}},
        { id:'nhan_vien_kinh_doanh',code:'nhan_vien_kinh_doanh',name:{"vi":"Nhân viên kinh doanh","en":"Business man"}},
        { id:'nhan_vien_giao_hang',code:'nhan_vien_giao_hang',name:{"vi":"Nhân viên giao hàng","en":"Delivery"}},
        { id:'quan_ly_kho',code:'quan_ly_kho',name:{"vi":"Quản lý kho","en":"Store manager"}},
        { id:'ky_thuat_vien',code:'ky_thuat_vien',name:{"en":"Technical service","vi":"Kỹ thuật viên"}},
        { id:'cham_soc_khach_hang',code:'cham_soc_khach_hang',name:{"en":"Customer service","vi":"Nhân viên chăm sóc khách hàng"}},
      ],
      temp_phone:null,
      processing:false,
      activetab: 1,
      options: {
				format: 'DD-MM-YYYY',
				useCurrent: false,
				showClear: true,
        showClose: true
      },
      money_discount: {
        decimal: ',',
        thousands: '.',
        prefix: '% ',
        precision: 0,
        masked: false
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '$ ',
        precision: 0,
        masked: false
      },
      info:{},
      company:{
        id:null,
        default_bill:null,
        name:null,
        bill_name:null,
        chieu_tu:'13:00',
        sang_den:'12:00',
        des:null,
        giay_phep_hoat_dong:null,
        ma_so_ho_so:null,
        code:null,
        address:null,
        phone:null,
        logo:null,
        sang_tu:'09:00',
        chieu_den:'18:00',
        cocon:false,
        discount:0,
        discount_reason:null,
        discount_all:false
      },
      branch:{
        id:null,
        name:null,
        bill_name:null,
        code:null,
        address:null,
        phone:null,
        company_id:null,
        chieu_tu:'13:00',
        sang_den:'12:00',
        sang_tu:'09:00',
        chieu_den:'18:00',
        branch_logo:null
      }
    }
  },
  validations: {
    branch: {
      name: {
        required: requiredIf(function () {
          return this.is_user
        }),
        maxLength:maxLength(50)
      },
      phone:{
        required,
        minLength: minLength(10),
        numeric,
        async isUnique(value) {
          debugger
          if(this.temp_phone&&this.temp_phone==value) return true

          const res = await this.branchPhoneUnique({phone:value,company_id:this.user_login.company_id})
          debugger
          return Boolean(res.data)
        }
      },
      sang_tu: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      sang_den: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      chieu_tu: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      chieu_den: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      }
    },
    company: {
      default_bill:{
        required
      },
      phone:{
        required,
        minLength: minLength(10),
        numeric,
        async isUnique(value) {
          debugger
          if(this.temp_phone&&this.temp_phone==value) return true
          const res = await this.companyPhoneUnique({phone:value,company_id:this.user_login.company_id})
          debugger
          return Boolean(res.data)
        }
      },
      sang_tu: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      sang_den: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      chieu_tu: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      chieu_den: {
        required: requiredIf(function () {
          return this.is_user && business!=Business.cafe && business!=Business.karaoke && business!=Business.hotel
        })
      },
      giay_phep_hoat_dong: {
        required: requiredIf(function () {
          return (this.business==Business.dentist && this.is_admin)
        })
      },
      ma_so_ho_so: {
        required: requiredIf(function () {
          return (this.business==Business.dentist && this.is_admin)
        })
      },
      name: {
        required,
        maxLength:maxLength(50)
      },
      code:{
        required,
        maxLength:maxLength(20)
      }
    }
  },
  async mounted () {
    let vm=this
    vm.company.bill_name=vm.bill_name[vm.$i18n.locale]
    vm.get_ls_branch_ddl(vm.user_login.company_id)
    vm.get_ls_company_ddl(vm.user_login.company_id)
    debugger
    if(vm.user_login){
      if(vm.user_login.branch_id){
        debugger
        let res=await vm.$store.dispatch('st_branch/get_by_id',{branch_id:vm.user_login.branch_id,company_id:vm.user_login.company_id})
        vm.branch.id=res.data.branch.id
        vm.branch.name=res.data.branch.name
        vm.branch.bill_name=res.data.branch.bill_name
        vm.branch.code=res.data.branch.code
        vm.branch.address=res.data.branch.address
        vm.branch.phone=res.data.branch.phone

        vm.branch.chieu_tu=res.data.branch.chieu_tu
        vm.branch.sang_den=res.data.branch.sang_den
        vm.branch.sang_tu=res.data.branch.sang_tu
        vm.branch.chieu_den=res.data.branch.chieu_den
        vm.branch.logo=res.data.branch.logo

        vm.branch.company_id= vm.user_login.company_id
        vm.temp_phone=res.data.branch.phone
        vm.info=res.data.info
        // vm.branch.bill_name=JSON.parse(res.data.branch.bill_name)[vm.$i18n.locale]
      }else{
        debugger
        let res=await vm.$store.dispatch('st_company/get_by_id',vm.user_login.company_id)
        vm.company=res.data.company
        vm.company.bill_name=JSON.parse(res.data.company.bill_name)[vm.$i18n.locale]
        vm.company.default_bill=res.data.company.default_bill
        vm.temp_phone=res.data.company.phone
        vm.info=res.data.info
      }
    }
  },
  computed: {
    bill_size(){
      let user=this.$store.state.st_authentication.user
      if(user.company.business==Business.cafe || user.company.business==Business.karaoke || user.company.business==Business.food ||user.company.business==Business.football
      || user.company.business==Business.hotel){
        return this.ppl2
      }
      return this.ppl
    },
    business(){
      let user=this.$store.state.st_authentication.user
      return user&&user.company.business
    },
    is_branch(){
      let user=this.$store.state.st_authentication.user
      return user&&user.branch_id>0
    },
    user_login(){
      return this.$store.state.st_authentication.user
    },
    cocon(){
      let user= this.$store.state.st_authentication.user
      return user && user.company.cocon
    },
    ls_company_ddl(){
      return this.$store.state.st_company.ls_company_ddl
    },
    is_super(){
      let user= this.$store.state.st_authentication.user
      return user && user.role==Role.Super_Admin
    },
    is_user(){
      let user= this.$store.state.st_authentication.user
      return user&&user.role==Role.User
    },
    is_admin(){
      let user= this.$store.state.st_authentication.user
      return user && (user.role==Role.Admin)
    },
    is_sub_admin(){
      let user= this.$store.state.st_authentication.user
      return user && (user.role==Role.Sub_Admin)
    },
    is_truong_chi_nhanh(){
      let user= this.$store.state.st_authentication.user
      return user && (user.role==Role.Branch_Admin)
    },
    ls_branch_ddl(){
      return this.$store.state.st_branch.ls_branch_ddl
    },
    ls_role_ddl(){
      let user=this.$store.state.st_authentication.user
      if(user.company.business==Business.vlxd||user.company.business==Business.tole
      ||user.company.business==Business.oto||user.company.business==Business.water)
      {
        return this.option_role_vlxd_tole_water_oto
      }
      return this.option_role
    }
  },
  methods: {
    ...mapActions('st_company', ['get_ls_company_ddl','']),
    ...mapActions('st_branch', ['get_ls_branch_ddl']),

    tabChanged(e){
      debugger
    },
    async companyPhoneUnique(param){
      debugger
      return await this.$store.dispatch(`st_company/profile_phone_unique`,param)
    },
    async branchPhoneUnique(param){
      return await this.$store.dispatch(`st_company/profile_phone_unique`,param)
    },
    async userPhoneUnique(param){
      return await this.$store.dispatch(`st_user/profile_phone_unique`,param)
    },
    companyAddTag (newTag) {
      const tag = {
        name: newTag,
        id: newTag
      }

      this.ls_company_ddl.push(tag)
    },
    getImage_company:function(e){
      let app=this
      debugger
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl_company").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.company.logo=e.target.result
      }
    },
    getImage_branch:function(e){
      let app=this
      let image=e.target.files[0]
      if(image.size>102400){
        app.$bvModal.show("viewModal")
        document.getElementById("fileControl_branch").value = ""
        return
      }
      let reader=new FileReader()
      reader.readAsDataURL(image)
      reader.onload=e=>{
        app.branch.logo=e.target.result
      }
    },
    async isValidCom () {
      debugger
      this.$v.company.$reset()
      this.$v.company.$touch()
      await this._validationsCom()
      return Promise.resolve(!this.$v.company.$error)
    },
    _validationsCom () {
      return new Promise(resolve => {
        if (this.$v.company.$error || !this.$v.company.$pending) {
          return resolve()
        }
        let poll = setInterval(() => {
          if (!this.$v.company.$pending) {
              clearInterval(poll)
              resolve()
          }
        }, 200)
      })
    },

    async saveCompany(){
      var app = this
      const isValid = await this.isValidCom()
      debugger
      if (!isValid) return
      if (app.processing === true) return
      app.processing = true
      if(app.user_login.role==Role.User) return

      app.$store.dispatch('st_company/update_company_profile',{lang:app.$i18n.locale,data:app.company})
      .then(function (resp) {
        debugger
        let user_temp=app.$store.state.st_authentication.user
        user_temp.company.cocon=app.company.cocon
        user_temp.company.logo=app.company.logo
        user_temp.discount=app.company.discount
        user_temp.discount_all=app.company.discount_all

        localStorage.setItem('user', JSON.stringify(user_temp))
        app.$store.commit('st_authentication/login_success', user_temp)
        app.processing = false
        app.$toastr.success(app.$t('common.updated_success'),app.$t("common.message"))
      })
      .catch(function (resp) {
        app.processing = false
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      })
    },
    async saveBranch(){
      var app = this
      debugger
      app.$v.branch.$touch()
      if(!app.$v.branch.$invalid) {
        debugger
        if(app.processing ===true) return

        app.processing = true

        app.branch.user_update_id=app.user_login.id
        app.$store.dispatch('st_branch/update_branch_profile',app.branch)
        .then(function (resp) {
          let user_temp=app.$store.state.st_authentication.user
          user_temp.branch_logo=app.branch.logo
          user_temp.branch_name=app.branch.name
          user_temp.branch_address=app.branch.address
          localStorage.setItem('user', JSON.stringify(user_temp))
          app.$store.commit('st_authentication/login_success', user_temp)
          app.processing = false
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
        })
        .catch(function (resp) {
          debugger
          app.processing = false
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      }
    }
  }
}
</script>
